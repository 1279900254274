<section class="why-xaan">
  <div class="container">
    <div class="row" style="padding-top: 60px">
      <div class="col-5 box-content mobo-reverse">
        <h2>Why Xaan?</h2>
        <h5>We Don't have too much content to bore you.</h5>
        <p class="mw-350">
          We understand the marketing strategies very better.Small scale or
          large scale business, we have them all covered, we are always one step
          ahead to make things very clear and understood. Online marketting is a
          game, in that game we have mastery over
        </p>
      </div>
      <div class="col-5">
        <img src="../../../assets/images/abouts.jpg" alt="about xaan" />
      </div>
    </div>
  </div>
</section>
