import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: [ './header.component.scss' ]
})
export class HeaderComponent implements OnInit {
	openMenuToggle: boolean = false;
	openNavMenu1: boolean = false;
	openNavMenu2:boolean = false;

	constructor() {}

	ngOnInit(): void {}

	openToggle() {
		console.log('clicked');
		this.openMenuToggle = !this.openMenuToggle;
	}
	whatwedo(){
		this.openNavMenu1 = !this.openNavMenu1;
		console.log('clicked');
	}
	whoweare(){
		this.openNavMenu2 = !this.openNavMenu2;
	}

}
