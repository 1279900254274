<section class="contact pt-40 pb-80">
  <div class="container">
    <div class="row">
      <div class="box-content col-6 pb-50">
        <h2>Hey! We Gotcha..</h2>
        <p>We create digital experience, excited to listen from you</p>

        <form style="padding-top: 25px" #f="ngForm" (click)="formValue(f)">
          <div class="form-group">
            <label>Name</label>
            <input
              type="text"
              required
              ngModel
              #name="ngModel"
              class="form-input"
              name="name"
              placeholder="your Name"
            />
            <span
              class="alert"
              style="color: red; font-size: 12px; padding-left: 3px"
              *ngIf="name.touched && !name.valid"
            >
              Please type your name
            </span>
          </div>
          <div class="form-group">
            <label>Email</label>
            <input
              type="email"
              required
              ngModel
              #email="ngModel"
              class="form-input"
              name="email"
              placeholder="your email"
            />
            <span
              class="alert"
              style="color: red; font-size: 12px; padding-left: 3px"
              *ngIf="email.touched && !email.valid"
              >Please enter valid email</span
            >
          </div>
          <div class="form-group">
            <label>Number</label>
            <input
              type="text"
              required
              ngModel
              #mobile="ngModel"
              class="form-input"
              name="number"
              placeholder="your number"
            />
            <span
              class="alert"
              style="color: red; font-size: 12px; padding-left: 3px"
              *ngIf="mobile.touched && !mobile.valid"
              >Mobile number is invalid</span
            >
          </div>
          <div class="form-group">
            <label>Message</label>
            <textarea
              class="form-input textarea"
              required
              ngModel
              #message="ngModel"
              name="body"
              placeholder="how we can help"
            ></textarea>
            <span
              class="alert"
              style="color: red; font-size: 12px; padding-left: 3px"
              *ngIf="message.touched && !message.valid"
              >Body message cannot be empty</span
            >
          </div>
          <button class="btn theme-btn">Submit</button>
        </form>
      </div>
      <div class="spacer col-1"></div>
      <div class="box-content col-3">
        <div>
          <h2>Email Us</h2>
          <a href="mailto:hello@xaan.in">info.xaan@gmail.com</a>
        </div>

        <div class="pt-20">
          <h2>Call Us</h2>
          <a href="tel:+919682520362">+91 9682 5203 62</a>
        </div>

        <div class="pt-20">
          <h2>Insights</h2>
          <a href="https://insights.xaan.in">Blog</a>
        </div>
      </div>
    </div>
  </div>
</section>
