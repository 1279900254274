<section class="banner top-banner">
  <div class="container">
    <div class="row pt-lg-50">
      <div class="col-5">
        <div class="banner-container">
          <h1>We Love Digital Beings</h1>
          <ul class="decoration-points">
            <li>Softwares we make for Business owners</li>
            <li>We build high scalable products</li>
          </ul>
          <button class="btn white-btn mt-40">View More</button>
        </div>
      </div>
    </div>
  </div>
</section>
