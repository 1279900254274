<span class="flex pb-20">
  <a href="https://www.facebook.com/xaan.in" target="_blank">
    <img src="../../assets/images/facebook.svg" alt="facebook icon" />
  </a>
  <a href="https://twitter.com/in_xaan" target="_blank">
    <img src="../../assets/images/twitter.svg" alt="twitter icon" />
  </a>
  <a href="https://www.linkedin.com/company/xaan/" target="_blank">
    <img src="../../assets/images/linkedin.svg" alt="linkedin icon" />
  </a>
  <a href="https://www.instagram.com/xaan.in/" target="_blank">
    <img src="../../assets/images/instagram.svg" alt="instagram icon" />
  </a>
</span>
