<section class="branding-banner top-banner">
  <div class="container">
    <div class="row">
      <div class="col-4">
        <div class="banner-container">
          <h2>Design your Brand</h2>
          <ul class="decoration-points">
            <li>Flyer designs</li>
            <li>Logo design</li>
            <li>flex design</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<app-cta></app-cta>
