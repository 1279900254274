import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component';
import { ServicesComponent } from './services/services.component';
import { BannerComponent } from './banner/banner.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { WebsiteComponent } from './website/website.component';
import { ECommerceComponent } from './e-commerce/e-commerce.component';
import { BrandingComponent } from './branding/branding.component';
import { WhyXaanComponent } from './component/why-xaan/why-xaan.component';
import { CtaComponent } from './component/cta/cta.component';
import { PoliciesComponent } from './policies/policies.component';
import { AngularFireModule } from '@angular/fire';
// import { AngularFireDatabaseModule } from "@angular/fire";
import { environment } from 'src/environments/environment';
import { SocialComponent } from './social/social.component';
import { UpcomingComponent } from './upcoming/upcoming.component';

@NgModule({
	declarations: [
		AppComponent,
		HeaderComponent,
		FooterComponent,
		HomeComponent,
		ContactComponent,
		AboutComponent,
		ServicesComponent,
		BannerComponent,
		PageNotFoundComponent,
		WebsiteComponent,
		ECommerceComponent,
		BrandingComponent,
		WhyXaanComponent,
		CtaComponent,
		PoliciesComponent,
		SocialComponent,
  UpcomingComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		FormsModule,
		HttpClientModule
		// AngularFireModule.initializeApp(environment.firebase),
		// AngularFireDatabaseModule,
	],
	providers: [],
	bootstrap: [ AppComponent ]
})
export class AppModule {}
