<div class="upcoming">
    <h1>
        Hold up tight
        <img src="../../assets/images/shuttle.png" alt="">
    </h1>
    
    <h4>We have a surprise for you
        <img src="../../assets/images/kite.png" alt="">
    </h4>
    <h4>Counting on (00.00 - 11.11)
        <img src="../../assets/images/confetti.png" alt="">
    </h4>
</div>


<style>
    .upcoming {
    position: absolute;
    top: 0;
    left: 0;
    bottom:0;
    right:0;
    width: 100%;
    height: 100%;
    background: #e7fff6;
    padding: 100px 40px;
}

h1,h4{
    display: flex;
    align-items: center;
}

img{
    margin-left: 10px;
    width:40px;
}
</style>