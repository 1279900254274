import { Component, OnInit, Input } from "@angular/core";


@Component({
  selector: "app-website",
  templateUrl: "./website.component.html",
  styleUrls: ["./website.component.scss"],
})
export class WebsiteComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {}
}
