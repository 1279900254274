<section class="header">
  <div class="container flex-between p-20">
    <div class="logo">
      <a routerLink="/">
        <!-- <img src="../../assets/images/xaan-logo.jpg" alt="xaan logo" /> -->
        <!-- <img src="../../assets/images/xaan-logo.svg" alt="xaan logo" /> -->
        <img src="../../assets/images/xaan-light-logo.svg" alt="xaan logo" />
      </a>
    </div>

    <nav class="nav-menu flex-center">
      <div class="menu">
        <ul class="flex-center">
          <div class="hide-mobile flex-center">
            <li class="pr-20" (click)="whatwedo()">
              <a href="javascript:void(0);">
                <i class="icon settings"></i>
                What We Do
              </a>
              <div class="dropdown" *ngIf="openNavMenu1">
                <li><a routerLink="/websites">Websites</a></li>
                <li><a routerLink="/e-commerce">E-Commerce</a></li>
                <li><a routerLink="/branding">Branding</a></li>
              </div>
            </li>
            <li class="pr-20" (click)="whoweare()">
              <a href="javascript:void(0);">
                <i class="icon group"></i>
                Who We Are
              </a>
              <div class="dropdown" *ngIf="openNavMenu2">
                <li><a routerLink="/about">About Us</a></li>
                <li><a routerLink="/contact">Contact Us</a></li>
              </div>
            </li>
            <li class="pr-20">
              <a href="https://insights.xaan.in">
                <i class="icon insight"></i>
                Blog
              </a>
            </li>
          </div>

          <li>
            <a routerLink="/contact" class="fancy-btn">
              <i class="icon light-bulb"></i>
              Hire Us
            </a>
          </li>
        </ul>
      </div>
      <div class="menu-toggle pl-20">
        <span (click)="openToggle()" class="menu-togle-icon">
          <i></i>
          <i></i>
          <i></i>
        </span>
      </div>
      <div *ngIf="openMenuToggle" class="main-menu">
        <div class="logo">
          <a routerLink="/">
            <!-- <img src="../../assets/images/xaan-logo.jpg" alt="xaan logo" /> -->
            <!-- <img src="../../assets/images/xaan-logo.svg" alt="xaan logo" /> -->
            <img
              src="../../assets/images/xaan-light-logo.svg"
              alt="xaan logo"
            />
          </a>
        </div>
        <ul>
          <span>
            <i class="icon settings"></i>
            What We Do
          </span>
          <li>
            <a routerLink="/websites">Websites</a>
          </li>
          <li>
            <a routerLink="/e-commerce">Ecommerce</a>
          </li>
          <li>
            <a routerLink="/branding">Branding</a>
          </li>
          <span>
            <i class="icon group"></i>
            Who We Are
          </span>
          <li>
            <a routerLink="/About">About</a>
          </li>
          <li>
            <a routerLink="/contact">Blog</a>
          </li>
          <li>
            <a routerLink="/contact">Contact Us</a>
          </li>

          <div class="social-menu">
            <app-social></app-social>
          </div>
        </ul>

        <span *ngIf="openMenuToggle" (click)="openToggle()" class="close"
          >close</span
        >
      </div>
    </nav>
  </div>
</section>
