<section class="footer">
  <div class="container">
    <div class="row pb-20">
      <div class="col-25 footer-logo box-content">
        <img src="../../assets/images/xaan-logo.jpg" alt="xaan logo" />
        <h4>Know your business, Grow your business</h4>
      </div>
      <div class="footer-menu box-content flex flex-mobo-column col-5">
        <div class="col-5 footer-menu__box pb-20">
          <h3>What we Do</h3>
          <a class="footer-menu__link" routerLink="/websites">Websites</a>
          <a class="footer-menu__link" routerLink="/e-commerce">Ecommmerce</a>
          <a class="footer-menu__link" routerLink="/branding">Branding</a>
        </div>
        <div class="col-5 footer-menu__box pb-20">
          <h3>Who we Are</h3>
          <a class="footer-menu__link" routerLink="/about">About Us</a>
          <a class="footer-menu__link" routerLink="/contact">Contact Us</a>
          <a class="footer-menu__link" href="https://insights.xaan.in">Blog</a>
          <a class="footer-menu__link" routerLink="/privacy-policy"
            >Privacy Policy</a
          >
        </div>
      </div>
      <div class="col-25 footer-menu__social-login box-content">
        <h3>Link Up Now</h3>
        <app-social></app-social>
      </div>
    </div>
    <div class="row py-15" style="border-top: 1px solid #f3eeee">
      <div class="box-content pt-20">
        <p>Copyright &copy; 2020 Xaan. All rights reserved</p>
      </div>
    </div>
  </div>
</section>
