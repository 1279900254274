import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-why-xaan',
  templateUrl: './why-xaan.component.html',
  styleUrls: ['./why-xaan.component.scss']
})
export class WhyXaanComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
