<section class="website-banner top-banner">
  <div class="container">
    <div class="row">
      <div class="col-4">
        <div class="banner-container">
          <h2>Website Design</h2>
          <ul class="decoration-points">
            <li>High scalable design</li>
            <li>Good UserExperience</li>
            <li>Introduce your ideas with world</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<app-cta></app-cta>
