import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { WebsiteComponent } from './website/website.component';
import { ECommerceComponent } from './e-commerce/e-commerce.component';
import { BrandingComponent } from './branding/branding.component';
import { AboutComponent } from './about/about.component';
import { PoliciesComponent } from './policies/policies.component';
import { UpcomingComponent } from './upcoming/upcoming.component';

const routes: Routes = [
	{path:'upcoming', component:UpcomingComponent},
	{path:'', redirectTo:'/upcoming', pathMatch:'full'},
	{ path: 'home', component: HomeComponent, },
	{ path: 'websites', component: WebsiteComponent },
	{ path: 'e-commerce', component: ECommerceComponent },
	{ path: 'branding', component: BrandingComponent },
	{ path: 'about', component: AboutComponent },
	{ path: 'contact', component: ContactComponent },
	{ path: 'privacy-policy', component: PoliciesComponent },
	{ path: '**', component: PageNotFoundComponent },
];

@NgModule({
	imports: [ RouterModule.forRoot(routes) ],
	exports: [ RouterModule ]
})
export class AppRoutingModule {}
