<app-banner></app-banner>

<div class="container">
  <div class="row pt-50">
    <h2 class="pt-50 text-light">What We DO</h2>
  </div>
  <div class="row">
    <div class="cards-container">
      <div class="card">
        <span class="flex-center pb-30">
          <img
            src="../../assets/images/website-icon.svg"
            alt="website design concept"
          />
          <h4>Websites</h4>
        </span>
        <span>
          <p>
            Tell us your business name and leave rest to us. Building a
            professionally designed website is the main focus where we can gain
            the customers attention.
          </p>
        </span>
        <span class="flex-center mt-40">
          <a routerLink="/websites" class="link">Learn More</a>
          <img
            src="../../assets/images/forward-arrow.svg"
            alt="forward-arrow"
            class="forward-arrow"
          />
        </span>
      </div>
      <div class="card">
        <span class="flex-center pb-30">
          <img
            src="../../assets/images/online-shopping.svg"
            alt="online shopping"
          />
          <h4>E-Commerce</h4>
        </span>
        <span>
          <p>
            Xaan offers also effective e-commerce solutions for small and medium
            businesses that want to grow online. Whether you need a fully
            functioning online store or a booking system that will allow
            customers to register for your services, we can help.
          </p>
        </span>
        <span class="flex-center mt-40">
          <a routerLink="/e-commerce" class="link">Learn More</a>
          <img
            src="../../assets/images/forward-arrow.svg"
            alt="forward-arrow"
            class="forward-arrow"
          />
        </span>
      </div>
      <div class="card">
        <span class="flex-center pb-30">
          <img
            src="../../assets/images/graphic-design.svg"
            alt="branding design"
          />
          <h4>Branding</h4>
        </span>
        <span>
          <p>
            Besides building websites, Xaan helps enterprises serving SMBs by
            delivering other scalable digital marketing solutions, including
            expert SEO plans, blog posts, and more. We can also support your
            digital operations by taking care of your site migrations and
            worldwide client managemen
          </p>
        </span>
        <span class="flex-center mt-40">
          <a routerLink="/branding" class="link">Learn More</a>
          <img
            src="../../assets/images/forward-arrow.svg"
            alt="forward-arrow"
            class="forward-arrow"
          />
        </span>
      </div>
    </div>
  </div>
</div>

<app-why-xaan></app-why-xaan>
<app-cta></app-cta>
