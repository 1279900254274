<section class="ecommerce-banner top-banner">
  <div class="container">
    <div class="row">
      <div class="col-5">
        <div class="banner-container">
          <h2>E-commerce Solutions</h2>
          <ul class="decoration-points">
            <li>get your physical store online</li>
            <li>Easily sell products</li>
            <li>Custom design templates</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

<app-cta></app-cta>
